// src/components/Features.js
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SensorsIcon from '@mui/icons-material/Sensors';
import NatureIcon from '@mui/icons-material/Nature';  // Güncellenmiş
import BarChartIcon from '@mui/icons-material/BarChart';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const FeaturesSection = styled.section`
  padding: 60px 20px;
  text-align: center;
  background: ${({ theme }) => theme.colors.background};

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const FeaturesTitle = styled.h2`
  margin-bottom: 40px;
  font-size: 2.5em;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureItem = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 50px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
`;

const FeatureText = styled.p`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.secondary};

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Features = () => {
  const { t } = useTranslation();

  return (
    <FeaturesSection id="features">
      <FeaturesTitle>{t('featuresTitle')}</FeaturesTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureIcon><ShoppingCartIcon /></FeatureIcon>
          <FeatureText>{t('feature1')}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon><ElectricBoltIcon /></FeatureIcon>
          <FeatureText>{t('feature2')}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon><AssessmentIcon /></FeatureIcon>
          <FeatureText>{t('feature3')}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon><SensorsIcon /></FeatureIcon>
          <FeatureText>{t('feature4')}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon><NatureIcon /></FeatureIcon> {/* Güncellenmiş */}
          <FeatureText>{t('feature5')}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon><BarChartIcon /></FeatureIcon>
          <FeatureText>{t('feature6')}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon><NatureIcon /></FeatureIcon> {/* Güncellenmiş */}
          <FeatureText>{t('feature7')}</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon><AnalyticsIcon /></FeatureIcon>
          <FeatureText>{t('feature8')}</FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </FeaturesSection>
  );
};

export default Features;
