// src/components/Integrations.js
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DevicesIcon from '@mui/icons-material/Devices';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ApiIcon from '@mui/icons-material/Api';

const IntegrationsSection = styled.section`
  padding: 60px 20px;
  text-align: center;
  background: ${({ theme }) => theme.colors.background};

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const IntegrationsTitle = styled.h2`
  margin-bottom: 40px;
  font-size: 2.5em;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const IntegrationsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const IntegrationItem = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }
`;

const IntegrationIcon = styled.div`
  font-size: 50px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
`;

const IntegrationText = styled.p`
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.secondary};

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Integrations = () => {
  const { t } = useTranslation();

  return (
    <IntegrationsSection id="learn-more">
      <IntegrationsTitle>{t('integrationsTitle')}</IntegrationsTitle>
      <IntegrationsGrid>
        <IntegrationItem>
          <IntegrationIcon><DevicesIcon /></IntegrationIcon>
          <IntegrationText>{t('integration1')}</IntegrationText>
        </IntegrationItem>
        <IntegrationItem>
          <IntegrationIcon><IntegrationInstructionsIcon /></IntegrationIcon>
          <IntegrationText>{t('integration2')}</IntegrationText>
        </IntegrationItem>
        <IntegrationItem>
          <IntegrationIcon><CloudSyncIcon /></IntegrationIcon>
          <IntegrationText>{t('integration3')}</IntegrationText>
        </IntegrationItem>
        <IntegrationItem>
          <IntegrationIcon><ApiIcon /></IntegrationIcon>
          <IntegrationText>{t('integration4')}</IntegrationText>
        </IntegrationItem>
      </IntegrationsGrid>
    </IntegrationsSection>
  );
};

export default Integrations;
