// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 20px 10px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const FooterTitle = styled.h3`
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const FooterLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center; /* İkon ve metni yatayda ortalamak için */
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 5px;
  font-size: 1em;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    margin-right: 8px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  a {
    color: ${({ theme }) => theme.colors.secondary};
    margin: 0 10px;
    font-size: 2em;
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const FooterText = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 20px;
  font-size: 0.9em;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>{t('brand')}</FooterTitle>
          <FooterLink href="/en">{t('home')}</FooterLink>
          <FooterLink href="/en#features">{t('features')}</FooterLink>
          <FooterLink href="/en#learn-more">{t('integrations')}</FooterLink>
          <FooterLink href="/en#contact">{t('contact')}</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>{t('contact')}</FooterTitle>
          <FooterLink href="mailto:info@fincarbon.co">
            <EmailIcon /> info@fincarbon.co
          </FooterLink>
          
          <FooterLink href="/en#contact">{t('contact')}</FooterLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Follow Us</FooterTitle>
          <SocialIcons>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><TwitterIcon /></a>
            <a href="https://www.linkedin.com/company/fin-carbon-yaz%C4%B1l%C4%B1m-ve-dan%C4%B1%C5%9Fmanl%C4%B1k" target="_blank" rel="noopener noreferrer"><LinkedInIcon /></a>
          </SocialIcons>
        </FooterSection>
      </FooterContent>
      <FooterText>&copy; 2024 FinCarbon. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;
