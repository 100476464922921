// src/theme.js
export const theme = {
  colors: {
    primary: '#07AD50', // Yeşil tema rengi
    secondary: '#4CAF50', // Daha açık yeşil
    background: '#F0F4F8', // Açık gri arka plan
    text: '#333333', // Koyu gri metin rengi
    accent: '#FFD700', // Altın vurgular
    link: '#007BFF', // Linkler için mavi
    footerBackground: '#073B4C', // Footer arka plan rengi
    footerText: '#FFFFFF', // Footer metin rengi
  },
};
