// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Integrations from './components/Integrations';
import Footer from './components/Footer';
import './i18n';
import { useTranslation } from 'react-i18next';
import { theme } from './theme';
import { logPageView } from './analytics';
import { SpeedInsights } from "@vercel/speed-insights/react"

const PageContent = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (lang && ['en', 'tr'].includes(lang)) {
      i18n.changeLanguage(lang);
    }
    logPageView(); // Sayfa görünümü kaydet
  }, [lang, i18n, location]);

  return (
    <>
      <Navbar />
      <Hero />
      <Features />
      <Integrations />
      <Footer />
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <SpeedInsights />
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<Navigate to="/en" replace />} />
          <Route path="/:lang" element={<PageContent />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
