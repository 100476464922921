// src/analytics.js
import ReactGA from 'react-ga';

const trackingId = "G-0LBYP5QJP1"; // Buraya kendi izleme kimliğinizi girin
ReactGA.initialize(trackingId);

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};
