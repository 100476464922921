// src/components/Hero.js
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from '@react-spring/web';

const HeroSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px 20px;
  background: ${({ theme }) => theme.colors.background};
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeroTextContainer = styled.div`
  max-width: 600px;
`;

const HeroTitle = styled(animated.h1)`
  font-size: 3em;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2.5em;
  }
`;

const HeroSubtitle = styled(animated.p)`
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const HeroButton = styled(animated.button)`
  padding: 10px 20px;
  font-size: 1.2em;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const HeroImage = styled(animated.img)`
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const BackgroundShapes = styled(animated.div)`
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  opacity: 0.2;
  z-index: -1;

  @media (max-width: 768px) {
    top: -30px;
    right: -30px;
    width: 150px;
    height: 150px;
  }
`;

const Hero = () => {
  const { t } = useTranslation();
  const titleProps = useSpring({ from: { opacity: 0, transform: 'translate3d(-50px,0,0)' }, to: { opacity: 1, transform: 'translate3d(0,0,0)' }, delay: 200 });
  const subtitleProps = useSpring({ from: { opacity: 0, transform: 'translate3d(-50px,0,0)' }, to: { opacity: 1, transform: 'translate3d(0,0,0)' }, delay: 400 });
  const buttonProps = useSpring({ from: { opacity: 0, transform: 'translate3d(-50px,0,0)' }, to: { opacity: 1, transform: 'translate3d(0,0,0)' }, delay: 600 });
  const imageProps = useSpring({ from: { opacity: 0, transform: 'scale(0.9)' }, to: { opacity: 1, transform: 'scale(1)' }, delay: 800 });
  const shapeProps = useSpring({ from: { opacity: 0, transform: 'scale(0)' }, to: { opacity: 1, transform: 'scale(1)' }, delay: 1000 });

  const handleButtonClick = () => {
    window.location.href = 'https://app.fincarbon.co/login';
  };

  return (
    <HeroSection>
      <HeroTextContainer>
        <HeroTitle style={titleProps}>{t('welcome')}</HeroTitle>
        <HeroSubtitle style={subtitleProps}>{t('heroText')}</HeroSubtitle>
        <HeroButton style={buttonProps} onClick={handleButtonClick}>{t('getStarted')}</HeroButton>
      </HeroTextContainer>
      <HeroImage style={imageProps} src="/undraw_real_time_analytics_re_yliv.svg" alt="Real Time Analytics" />
      <BackgroundShapes style={shapeProps} />
    </HeroSection>
  );
};

export default Hero;
