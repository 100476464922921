// src/components/Navbar.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Nav = styled.nav`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box; /* Ensure padding is included in width calculations */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const NavBrand = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5em;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  height: 40px; /* Adjust size as needed */
  margin-right: 10px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid ${({ theme }) => theme.colors.text};
  }
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-size: 1em;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
  }
`;

const ToggleButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5em;

  @media (max-width: 768px) {
    display: block;
    align-self: center;
  }
`;

const Navbar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav>
      <NavBrand href="#">
        <Logo src="/Fin CARBON logo .png" alt="Fin Carbon Logo" />
      </NavBrand>
      <ToggleButton onClick={toggleMenu}>&#9776;</ToggleButton>
      <NavLinks isOpen={isOpen}>
        <NavLink href="#">{t('home')}</NavLink>
        <NavLink href="#features">{t('features')}</NavLink>
        <NavLink href="#learn-more">{t('integrations')}</NavLink>
        <NavLink href="#contact">{t('contact')}</NavLink>
      </NavLinks>
    </Nav>
  );
};

export default Navbar;
